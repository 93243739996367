<template>
    <div class="w-full px-6 py-3 bg-pink-100 rounded-3xl items-center gap-2 inline-flex  cursor-pointer" :class="[justifyClass, sizeClass]">
        <div class="text-zinc-800 text-base font-normal leading-normal whitespace-nowrap">
            {{ text }}
        </div>
    </div>
</template>

<script>
export default {
    props: ["text", "justify", "size"],
    computed: {
        justifyClass() {
            if (this.justify) {
                return `justify-${this.justify}`;
            }
            return  `justify-center`;
        },
        sizeClass() {
            if (this.size) {
                return `h-${this.size}`;
            }
            return `h-12`;
        }
    }
}
</script>
